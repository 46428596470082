import React, { useEffect } from "react"
import { navigate } from "gatsby"

const GuidingCriteria = () => {
  // This is a redirect route to guiding-criteria page
  useEffect(() => {
    navigate("/guiding-criteria")
  }, [])

  return <div />
}

export default GuidingCriteria
